import * as React from "react"
import MainLayout from "../layouts/MainLayout"

import SEO from '../components/SEO'
import Page from '../components/Page'
import { graphql } from "gatsby"
import Hero from "../components/videos/Hero"

export default function Video ({data}) {
  return (
      <MainLayout>
        <SEO title={data.strapiVideos.Seo.Title} description={data.strapiVideos.Seo.Description} />
        <Hero sources={data.strapiVideos} />
        <Page data={data.strapiVideos} />
      </MainLayout>
  )
}


export const pageQuery = graphql`  
query($slug: String!) {
    strapiVideos(Slug: { eq: $slug }) {
      Description
      Slug
      Title
      YoutubeCode
      Seo {
        Description
        Keywords
        Title
      }
      VideoThumbnail {
        localFile {
          publicURL
        }
      }
      Image {
        localFile {
          publicURL
        }
      }
    }
  }
`
